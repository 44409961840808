<template>
  <nav id="sidebar">
    <div class="socials">
      <a href="https://www.linkedin.com/in/kobe-schreurs/" target="_blank">LinkedIn</a>
      <a href="https://github.com/KobeSchreurs" target="_blank">GitHub</a>
      <a href="https://open.spotify.com/user/21fdy5vljk7zzfnwobqtfll5y?si=e7bfbba8befc472f" target="_blank">Spotify</a>
      <a href="https://www.facebook.com/Kobe.Sch/" target="_blank">Facebook</a>
      <a href="https://www.instagram.com/kobeschreurs/" target="_blank">Instagram</a>
    </div>
  </nav>
</template>


<style scoped>
@media screen and (max-width: 1200px) {
  #sidebar {
    display: none;
  }
}

#sidebar {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 150px;
}

nav a {
  flex-wrap: wrap;
  display: block;
  text-align: left;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: bold;

}

nav a:hover {
  background-color: rgb(66, 4, 126, 0.5);
  color: #EFF1C5;

}
</style>